import { MFLazyLoad } from 'js/shared/mf-lazyload.js';
import 'js/shared/logging.js';
if (process.env.NODE_ENV === 'development') {
    // enable preact devtools
    require('preact/debug');
}

const mfLoader = new MFLazyLoad('.lazy');
mfLoader.update();
const header = document.getElementById('header');
if (header) {
    const notice = document.getElementById('notice');

    function change_header() {
        const scroll_position = window.scrollY;
        if (scroll_position > 50) {
            document.getElementById('header').classList.add('header-shadow');
        } else {
            document.getElementById('header').classList.remove('header-shadow');
        }

        if (notice) {
            if (scroll_position > 0) {
                notice.classList.add('hidden');
            } else {
                notice.classList.remove('hidden');
            }
        }
    }
    change_header();
    window.addEventListener('scroll', change_header);

    const main_navigation = document.getElementById('main-navigation');
    const mobile_open_icon = document.getElementById('mobile-menu-icon');
    const mobile_close_icon = document.getElementById('mobile-menu-close-icon');

    // Horizontal was set to display none on mobile screen sizes
    document.getElementById('mobile-menu').addEventListener('click', (e) => {
        e.preventDefault();
        mobile_open_icon.classList.toggle('mobile-menu-hide');
        mobile_close_icon.classList.toggle('mobile-menu-hide');
        main_navigation.classList.toggle('pure-menu-vertical');
    });

    // We reset the menu state on resize, to ensure it's not in some dumb state
    window.addEventListener('resize', (e) => {
        main_navigation.classList.add('pure-menu-horizontal');
        main_navigation.classList.remove('pure-menu-vertical');
        mobile_open_icon.classList.remove('mobile-menu-hide');
        mobile_close_icon.classList.add('mobile-menu-hide');
    });

    const top_navs = [...header.querySelectorAll('.pure-menu-link.primary')];
    // We set data-id attributes to identify these in the hover event
    let nav_counter = 0;
    top_navs.map((node) => {
        node.setAttribute('data-id', nav_counter);
        node.parentNode.setAttribute('data-id', nav_counter);
        ++nav_counter;
    });
    let nav_timer = null;
    header.addEventListener('mouseover', (e) => {
        try {
            if (e.target && e.target.classList.contains('pure-menu-link')) {
                clearTimeout(nav_timer);
                const focused_navs = [
                    ...header.querySelectorAll('.pure-menu-active'),
                ];
                let target = null;
                if (e.target.classList.contains('primary')) {
                    // Handle non-child nav hovers
                    target = e.target.parentNode;
                } else {
                    // Handle child nav hovers
                    target = e.target.parentNode.parentNode.parentNode;
                    // Yeah, I know.
                }
                target.classList.add('pure-menu-active');
                const id = target.getAttribute('data-id');
                if (focused_navs.length > 0) {
                    focused_navs.map((node) => {
                        const nav_id = node.getAttribute('data-id');
                        if (nav_id !== id) {
                            node.classList.remove('pure-menu-active');
                        }
                    });
                }
            }
        } catch (err) {
            console.log(err);
        }
    });

    // On mouseout we set a nav timer to clear the active menu item
    header.addEventListener('mouseout', (e) => {
        try {
            if (e.target && e.target.classList.contains('pure-menu-link')) {
                clearTimeout(nav_timer);
                let target = null;
                if (e.target.classList.contains('primary')) {
                    // Handle non-child nav hovers
                    target = e.target.parentNode;
                } else {
                    // Handle child nav hovers
                    target = e.target.parentNode.parentNode.parentNode;
                    // Yeah, I know.
                }
                nav_timer = setTimeout(() => {
                    target.classList.remove('pure-menu-active');
                }, 1000);
            }
        } catch (err) {
            console.log(err);
        }
    });

    /**
     * This kicks in on mobile/touch screens. Whenever the primary
     * link for a group of children is clicked, it shows the children.
     */
    document.addEventListener('click', (e) => {
        if (
            e.target &&
            e.target.classList.contains('primary') &&
            e.target.parentNode.classList.contains('pure-menu-has-children')
        ) {
            const node = e.target;
            [].forEach.call(
                node.parentNode.querySelectorAll('.pure-menu-children'),
                (el) => {
                    el.classList.toggle('child-open');
                }
            );
        }
    });
}

// document.addEventListener('click', (e) => {
//     if (e.target && e.target.classList.contains('open-chat')) {
//         const f9 = document.getElementById('five9-maximize-button');
//         f9 && f9.click();
//     }
// });

const tabs = document.querySelectorAll('.tab');
if (tabs) {
    document.addEventListener('click', (e) => {
        if (e.target && e.target.classList.contains('tab')) {
            if (e.target.classList.contains('active')) {
                return;
            }
            const parent = e.target.closest('.tabs');
            if (!parent) {
                return;
            }
            [...parent.querySelectorAll('.tab')].map((x) => {
                const hit = document.getElementById(
                    x.getAttribute('data-target')
                );
                hit && hit.classList.add('hidden');
                x.classList.remove('active');
            });

            const hit = document.getElementById(
                e.target.getAttribute('data-target')
            );
            hit && hit.classList.remove('hidden');
            e.target.classList.add('active');
        }
    });
}
