/**
 * Welcome to MF Lazy Load.
 * Made lazily.
 * To be lazy.
 *
 * No seriously, this is the laziest I could be, to accomplish
 * a single task as simply as possible.
 *
 * That task?
 *
 * Load the background images on the home page, based on screen width
 * and laziness.
 *
 * That's it.
 *
 * I'll flesh this more out later when I have time.
 * And simplify it.
 *
 * For now, it expects a data-bg attribute that is a full on JSON.
 *
 * Like so:
 * {
 *     [
 *         "url": "url('idk.jpg')",
 *         "min-width": 0
 *     ]
 * }
 *
 * Pretty self-explanatory for the moment.
 */

function MFLazyLoad(target) {
    const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];
        const x = w.innerWidth || e.clientWidth || g.clientWidth;
        const y = w.innerHeight || e.clientHeight || g.clientHeight;
    const screen_width = x;
    this.update = function() {
        const targets = document.querySelectorAll(target);
        [...targets].map((el) => {
            const bg_raw = el.getAttribute('data-bg');
            const bg_settings = JSON.parse(bg_raw);
            for (const key in bg_settings) {
                if (bg_settings[key]['min-width'] <= screen_width) {
                    el.style['background-image'] = bg_settings[key].url;
                    break;
                }
            }
        });
    };
}

export {MFLazyLoad};
