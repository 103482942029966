// Credit: https://stackoverflow.com/a/59434639/3776801

const Browser = {};
const vendor = (navigator && navigator.vendor || '').toLowerCase();
const userAgent = (navigator && navigator.userAgent || '').toLowerCase();

Browser.getBrowserName = () => {
  if (isOpera()) return 'opera'; // Opera
  else if (isChrome()) return 'chrome'; // Chrome
  else if (isFirefox()) return 'firefox'; // Firefox
  else if (isSafari()) return 'safari'; // Safari
  else if (isInternetExplorer()) return 'ie'; // Internet Explorer
};

// Start Detecting browser helpers functions
function isOpera() {
  const isOpera = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
  return isOpera !== null;
}

function isChrome() {
  const isChrome = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
  return isChrome !== null;
}

function isFirefox() {
  const isFirefox = userAgent.match(/(?:firefox|fxios)\/(\d+)/);
  return isFirefox !== null;
}

function isSafari() {
  const isSafari = userAgent.match(/version\/(\d+).+?safari/);
  return isSafari !== null;
}

function isInternetExplorer() {
  const isInternetExplorer = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/);
  return isInternetExplorer !== null;
}
// End Detecting browser helpers functions

export {Browser};
